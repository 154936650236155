.ion-color-theme {
  --ion-color-base: var(--ion-color-theme);
  --ion-color-base-rgb: var(--ion-color-theme-rgb);
  --ion-color-contrast: var(--ion-color-theme-contrast);
  --ion-color-contrast-rgb: var(--ion-color-theme-contrast-rgb);
  --ion-color-shade: var(--ion-color-theme-shade);
  --ion-color-tint: var(--ion-color-theme-tint);
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-light-gray {
  --ion-color-base: var(--ion-color-light-gray);
  --ion-color-base-rgb: var(--ion-color-light-gray-rgb);
  --ion-color-contrast: var(--ion-color-light-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-gray-shade);
  --ion-color-tint: var(--ion-color-light-gray-tint);
}

.ion-color-dark-greenish {
  --ion-color-base: var(--ion-color-dark-greenish);
  --ion-color-base-rgb: var(--ion-color-dark-greenish-rgb);
  --ion-color-contrast: var(--ion-color-dark-greenish-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-greenish-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-greenish-shade);
  --ion-color-tint: var(--ion-color-dark-greenish-tint);
}

.ion-color-dark-blue {
  --ion-color-base: var(--ion-color-dark-blue);
  --ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
  --ion-color-contrast: var(--ion-color-dark-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-blue-shade);
  --ion-color-tint: var(--ion-color-dark-blue-tint);
}
