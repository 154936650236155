.header-md::after {
  background-image: none;
}

.header-ios::after {
  background-image: none;
}

.footer-md::before {
  background-image: none;
}

.footer-ios::before {
  background-image: none;
}

ion-menu-button {
  ion-icon.ios {
    font-size: 20px;
  }
}

ion-title {
  font-size: 18px;
  font-weight: 400;
  font-family: openSans-bold, sans-serif;
  letter-spacing: 0.4px;
  text-align: center;
  color: var(--ion-color-theme);
}

.error-container {
  .error-message {
    display: flex;
    align-items: center;
    color: var(--ion-color-danger);
    font-size: 14px;
    padding-left: 16px;
    padding-bottom: 16px;

    ion-icon {
      padding-inline-end: 2px;
    }
  }
}

.btn {
  height: 50px;
  --border-radius: 10px;
  --box-shadow: none;
  font-family: raleway-bold, sans-serif;
  min-width: 120px;
}

.card-view-with-edit-delete-icon {
  background: #ffffff;
  border-radius: 11px;
  margin: 16px 16px 24px 16px;
  box-shadow: 0 0 20px 20px #f8faf8;

  ion-thumbnail {
    --border-radius: 11px;
    width: 100%;
    height: 91px;
  }

  .image {
    padding: 11px 0 11px 11px;
  }
}

.delete-edit-icon {
  width: 30%;
  height: 32px;
  background: var(--ion-color-theme);
  position: absolute;
  top: 86%;
  left: 85.6%;
  transform: translate(-50%, -50%);
  align-items: center;
  font-size: 22px;
  padding-top: 5px;
  text-align: center;
  border-radius: 10px 0 10px 0;
}

.select-input {
  ion-select {
    width: 100%;
  }

  --background: var(--ion-color-light-gray);
  border-radius: 6px;
  padding-bottom: 0;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 10px;
}

app-add-ads,
app-add-weapon {
  .photos {
    padding: 16px 16px 16px 7px;

    ion-card {
      width: 90%;
      height: 100px;
      box-shadow: none;
      background: var(--ion-color-light-gray);
      border-radius: 8px;
    }

    .add-icon {
      font-size: 22px;
      padding-top: 26px;
      padding-bottom: 1px;
    }

    .close-icon {
      width: 32px;
      height: 32px;
      background: var(--ion-color-theme);
      position: absolute;
      top: 84%;
      left: 87%;
      transform: translate(-50%, -50%);
      border-top-left-radius: 10px;
      align-items: center;
      font-size: 22px;
      padding-top: 5px;
    }
  }
}
