@font-face {
  font-family: poppins-bold;
  src: url(../assets/fonts/poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: poppins-regular;
  src: url(../assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: poppins-medium;
  src: url(../assets/fonts/poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: poppins-semiBold;
  src: url(../assets/fonts/poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: raleway-bold;
  src: url(../assets/fonts/raleway/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: openSans-bold;
  src: url(../assets/fonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: openSans-regular;
  src: url(../assets/fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}
